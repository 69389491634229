import { createSlice } from '@reduxjs/toolkit';

// utils
import axios, { endpoints } from '../../utils/axios'; // import dispatch from store

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  listings: {},
  currentListing: null,
};

const slice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    // START LOADING
    startLoading (state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading (state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError (state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // RESET ERROR
    resetError (state, action) {
      state.isLoading = false;
      state.error = null;
    },

    // GET LISTINGS
    getListingsSuccess (state, action) {
      const listings = action.payload;
      state.listings = listings;
      state.isLoading = false;
    },

    // GET LISTING
    getListingSuccess (state, action) {
      const listing = action.payload;
      state.currentListing = listing;
      state.isLoading = false;
    },

    // CREATE LISTING
    createListingSuccess (state, action) {
      const listing = action.payload;
      state.currentListing = listing;
      state.isLoading = false;
    },

    // UPDATE LISTING
    updateListingSuccess (state, action) {
      const listing = action.payload;
      state.currentListing = listing;
      state.isLoading = false;
    },

    // DELETE LISTING
    deleteListingSuccess (state, action) {
      const listing = action.payload;
      state.currentListing = listing;
      state.isLoading = false;
    }
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getListings () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = endpoints.listing.list;
      const response = await axios.get(url);
      dispatch(slice.actions.getListingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------
export function getListing (listingId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const URL = `${endpoints.listing.details}/${listingId}`;

      const response = await axios.get(URL);
      dispatch(slice.actions.getListingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------

export function createListing (listing) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'post',
        url: endpoints.listing.create,
        data: listing,
        headers: {
          'Content-Type': 'multipart/form-data' // This line is often unnecessary as Axios and browsers handle it automatically
        }
      });

      dispatch(slice.actions.createListingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}


// ----------------------------------------------------------------------

export function updateListing (listing) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      console.log("Listing from update api=======>", listing);



      const response = await axios({
        method: 'put',
        url: endpoints.listing.update,
        data: listing,
        headers: {
          'Content-Type': 'multipart/form-data' // This line is often unnecessary as Axios and browsers handle it automatically
        }
      });
      dispatch(slice.actions.updateListingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}


// ----------------------------------------------------------------------

export function deleteListing (ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(endpoints.listing.delete, { data: { ids } })
      dispatch(slice.actions.deleteListingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------
export function bulkCreateListings (listings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(endpoints.listing.bulkCreate, { listings })
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------

export function importListings (platform, listings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(endpoints.listing.import, { platform, listings })
      dispatch(slice.actions.endLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------
export function fetchListings (platform) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${endpoints.listing.list}/${platform}`)
      dispatch(slice.actions.endLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

// ----------------------------------------------------------------------
export function generateListingDetails (images) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(endpoints.listing.generate, { images })
      dispatch(slice.actions.endLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}



//----------------------------------------------------------------------
export function resetError () {
  return async (dispatch) => {
    dispatch(slice.actions.resetError());
  };
}

//----------------------------------------------------------------------

